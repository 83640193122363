export const orderConst = {
  ORDER_POST_REQUEST: "ORDER_POST_REQUEST",
  ORDER_POST_SUCCESS: "ORDER_POST_SUCCESS",
  ORDER_POST_FAIL: "ORDER_POST_FAIL",

  ORDERS_GET_REQUEST: "ORDERS_GET_REQUEST",
  ORDERS_GET_SUCCESS: "ORDERS_GET_SUCCESS",
  ORDERS_GET_FAIL: "ORDERS_GET_FAIL",
  ORDER_POST_ORDER_ID: "ORDER_POST_ORDER_ID"
};
