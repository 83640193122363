// react
import React, { useCallback, useEffect, useRef, useState } from "react";

// third-party
import { withRouter,useLocation } from "react-router-dom";
import {CircularProgress} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';



import Suggestions from "./Suggestions";
// import { Cross20Svg, Search20Svg } from "../../svg";
// import { _Search } from "../../core/api/home";

import {getSearchProduct} from "../../redux/actions/home-actions";
import {useSelector,useDispatch} from "react-redux";

import {useTranslation} from "react-i18next";


function Search(props) {

  const [cancelFn, setCancelFn] = useState(() => () => {});
  const [suggestionsOpen, setSuggestionsOpen] = useState(false);
  // const [loading, setLoading] =
  const [hasSuggestions, setHasSuggestions] = useState(false);
  const [query, setQuery] = useState("");

  const {t} = useTranslation()

  let locacation = useLocation()

  const dispatch = useDispatch()
const {loading} = useSelector(state =>state.homeSearch )
const inputRef = useRef(null)
  const wrapper = useRef(null);
  const close = useCallback(() => {



    setSuggestionsOpen(false);


  }, []);




  useEffect(() => {
    const onGlobalClick = (event) => {
      if (wrapper.current && !wrapper.current.contains(event.target)) {
        close();
      }
    };
    document.addEventListener("mousedown", onGlobalClick);
    return () => document.removeEventListener("mousedown", onGlobalClick);
  }, [close]);

  // Cancel previous typing.
  useEffect(() => () => cancelFn(), [cancelFn]);

  useEffect(()=>{
    setSuggestionsOpen(false)
  },[locacation])

  const handleFocus = () => {
    setSuggestionsOpen(true);
  };

  const handleChangeQuery = (event) => {
    let canceled = false;
    let timer;


    const newCancelFn = () => {
      canceled = true;
      clearTimeout(timer);
    };


    const query = event.target.value;

    setQuery(query);
    dispatch(getSearchProduct(query))
    setSuggestionsOpen(true)

    if (query === "") {
      setHasSuggestions(false);
    } else {
      // setLoading(true);
      timer = setTimeout(() => {
        const options = { limit: 5 };


      }, 600);
    }

    setCancelFn(() => newCancelFn);
  };

  const handleBlur = () => {
    setTimeout(() => {


      if (!document.activeElement || document.activeElement === document.body) {
        return;
      }

      // Close suggestions if the focus received an external element.
      if (wrapper.current && !wrapper.current.contains(document.activeElement)) {
        close();
      }
    }, 10);
  };

  // Close suggestions when the Escape key has been pressed.
  const handleKeyDown = (event) => {
    // Escape.
    if (event.which === 27) {
      close();
    }
  };


  return (
    <div  ref={wrapper} onBlur={handleBlur} className='w-100'>
      <div className="search__body">
        <form className="search__form" action="" onSubmit={(e)=>e.preventDefault()}>
          <input
             ref={inputRef}
            onChange={handleChangeQuery}
            onFocus={handleFocus}
            onKeyDown={handleKeyDown}
            value={query}
            className="search__input"
            name="search"
            placeholder={t("search_product")}
            aria-label="Site search"
            type="text"
            autoComplete="off"
          />
          {!loading ? (
            <div className="search__button">
              {/*<Search20Svg />*/}
              <SearchIcon fontSize="large" className='mt-2'/>
            </div>
          ) : (
            <div className="text-center ml-2">
              {query.length>0 &&<CircularProgress style={{width:"20px",height:"20px"}}/>}
            </div>
          )}
          {/*{closeButton}*/}
          <div className="search__border" />
        </form>
        {suggestionsOpen && query.length>0 &&
          <Suggestions className="search__suggestions"  setSuggestionopen={ setSuggestionsOpen}/>
        }
      </div>
    </div>
  );
}
export default withRouter(Search);
