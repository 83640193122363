import { orderConst } from "../constant/order-constant";

export const orderPostReducer = (state = { orderData: [], orderId: 0 }, action) => {
  switch (action.type) {
    case orderConst.ORDER_POST_REQUEST:
      return { loading: true, orderData: action.payload };

    case orderConst.ORDER_POST_SUCCESS:
      return { loading: false, orderData: action.payload };
    case orderConst.ORDER_POST_ORDER_ID:
      return { loading: false, orderId: action.payload };
    case orderConst.ORDER_POST_FAIL:
      return { loading: false, orderData: action.payload };
    default:
      return state;
  }
};

export const ordersListGetReducer = (state = { ordersList: [] }, action) => {
  switch (action.type) {
    case orderConst.ORDERS_GET_REQUEST:
      return { loading: true };
    case orderConst.ORDERS_GET_SUCCESS:
      return { loading: false, ordersList: action.payload };
    case orderConst.ORDERS_GET_FAIL:
      return { loading: false, ordersList: [] };
    default:
      return state;
  }
};
