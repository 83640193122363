// react
import React from "react";

// third-party
import classNames from "classnames";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Button } from "@material-ui/core";
import { AddShoppingCart } from "@material-ui/icons";
import { addToCart, updateCart } from "../../redux/actions/cart-action";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";

function Suggestions(props) {
  const dispatch = useDispatch();
  const cardList = useSelector((state) => state.cartData.cardList);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const addCart = (item) => {
    let fa =
      t("direction") === "rtl"
        ? `${item?.name} ${t("Cart_SUCCESS_ADD")}`
        : `${item?.name} ${t("Cart_SUCCESS_ADD")}`;
    enqueueSnackbar(fa, { variant: "default" });

    if (cardList) {
      const index = cardList.findIndex((i) => i.id === item.id);
      if (index < 0) {
        dispatch(addToCart({ ...item, card_num: 1, amount: item.min_price }));
      } else {
        const cardNum = cardList[index].card_num;

        dispatch(updateCart(item.id, { card_num: cardNum + 1 }));
      }
    } else {
      dispatch(addToCart({ ...item, card_num: 1 }));
    }
  };

  const { context, className } = props;
  const rootClasses = classNames(
    `suggestions suggestions--location--${context}`,
    className
  );
  // const { products, brands, categories } = product;
  const products = useSelector((state) => state.homeSearch.searchProduct);

  const productsList = products?.map((item, index) => (
    <li key={index} className="suggestions__item px-4 ">
      <Link
        to={`/products/${item?.slug}`}
        key={index}
        className="text-dark d-flex align-items-center w-75"
      >
        {item.photo && item.photo.length > 0 && (
          <div className="suggestions__item-image item-image">
            <div className="product-image__body">
              <img className="product-image__img" src={item.photo} alt="" />
            </div>
          </div>
        )}
        <div className="suggestions__item-info">
          <span>{item.name}</span>
        </div>
      </Link>
      {/*<Button*/}
      {/*    onClick={addCart(item)}*/}
      {/*    className="btn_add_Cart  mr-auto ml-2"*/}
      {/*    variant='contained'*/}
      {/*    color='primary'*/}
      {/*>*/}
      {/*    <AddShoppingCart fontSize='large'/>*/}
      {/*</Button>*/}
      <div className={"Cart_list_items btn"}>
        <div className="cartBtn">
          <div className="cartBtn_cartIconNum">
            <Button
              size={"medium"}
              disabled={item.qty === 0}
              onClick={() => addCart(item, "success")}
              className={`btn_add_Cart  ${t("direction") === "rtl" ? "mr-4" : "ml-4"
                }`}
            >
              <AddShoppingCart style={{ color: "#00A693" }} />
            </Button>
            <span
              className="cardNum"
              style={t("direction") !== "rtl" ? { right: "-10px" } : {}}
            >
              {(() => {
                const index = cardList?.findIndex((i) => i.id === item.id);
                const cardNum = index > -1 ? cardList[index].card_num : 0;
                return cardNum;
              })()}
            </span>
          </div>
        </div>
      </div>
    </li>
  ));

  return (
    <div className={rootClasses}>
      <ul
        className="suggestions__list"
        style={{ overflowY: "scroll", maxHeight: "400px" }}
      >
        {products && <>{productsList}</>}
      </ul>
    </div>
  );
}

export default Suggestions;
