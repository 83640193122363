import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'
import "./App/Assets/scss/style.scss";
import { Provider } from "react-redux";
import App from "./App.js";
import store from "./App/redux/store";
import "./i18nextConf";


ReactDOM.hydrate(
    <Suspense fallback={".."}>
        <Provider store={store}>
            <App />
        </Provider>
    </Suspense>
    ,
    document.getElementById("root")
);
