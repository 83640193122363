import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import backend from "i18next-http-backend";

import HttpApi from "i18next-http-backend";
import cookie from "js-cookie";
import LanguageDetector from "i18next-browser-languagedetector";


const fallbackLng = ["fa"];
const availableLanguages = ["fa","en"];



i18n
    .use(initReactI18next)
    .use(backend)
    .use(LanguageDetector)
    .use(HttpApi)
    .init({
        supportedLngs: ["fa", "en"],
        // fallbackLng: "fa",
        // fallbackLng: "en",
        detection: {
            order: ["path", "cookie", "htmlTag", "localStorage", "subdomain"],
            caches: ["cookie"],
        },
        // lng: "fa",
        // fallbackLng: "fa",
        backend: {
            loadPath: "/assets/locales/{{lng}}/Translations.json",
        },
    });

export default i18n;
