import { lazy, Suspense } from "react";
import { Router, Switch, Route } from "react-router-dom";
import Loading from "../feature/Loading";
import DefaultLayout from "../layout/DefaultLayout.jsx";
import Main from "../layout/main/Main";
import { history } from "./history";
import Error404 from "../pages/Error404";

///Routing
const Login = lazy(() => import("../pages/Login.js"));
const Register = lazy(() => import("../pages/Registration.js"));
const ProductCategories = lazy(() =>
    import("../components/Product/ProductCategories.jsx")
);
const ProductDetials = lazy(() =>
    import("../components/Product/ProductDetials.jsx")
);
const Checkout = lazy(() => import("../components/Checkout/Checkout"));
const Profile = lazy(() => import("../components/Profile/Profile"));
const OrderDetails = lazy(() => import("../components/Profile/OrderDetails"));
const Categories = lazy(() => import("../components/categori/categories"));
const Verifyotp = lazy(() => import("../pages/Verifyotp"));
const verifyregister = lazy(() => import("../pages/verify-register"));
const FinalPayment = lazy(() =>
    import("../components/FinalPayment/FinalPayment")
);
const PrivacyPolicy = lazy(() => import("../pages/privacy-policy"))
const SearchPage = lazy(() => import('../pages/Search-page'))
const NavPage = lazy(() => import("../components/NavPage/NavPage"))
const routes = [

    { url: "/", component: Main },
    { url: "login", component: Login },
    { url: "register", component: Register },
    { url: "verify", component: Verifyotp },
    { url: "products", component: ProductCategories },
    { url: "products/:slug", component: ProductDetials },
    { url: "categories/:slug", component: Categories },
    { url: "checkout", component: Checkout },
    { url: "profile", component: Profile },
    { url: "finalpayment/:id?", component: FinalPayment },
    { url: "verify-register", component: verifyregister },
    { url: "profile/orders/:id", component: OrderDetails },
    { url: 'privacy-policy', component: PrivacyPolicy },
    { url: 'search/:slug', component: SearchPage },
    { url: 'page/:slug', component: NavPage },
];
const MainRoute = () => {
    return (
        <>
            <Router history={history}>
                <Suspense fallback={<Loading />}>
                    <DefaultLayout>
                        <Switch>
                            {routes.map((data, i) => (
                                <Route
                                    key={i}
                                    exact
                                    path={`/${data.url}`}
                                    component={data.component}
                                />
                            ))}
                            <Route component={Error404} />
                        </Switch>
                    </DefaultLayout>
                </Suspense>
            </Router>
        </>
    );
};
export default MainRoute;
