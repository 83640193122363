import {
  auth,
  auth_otp,
  register,
  verifyConst,
} from "../constant/auth-constant";

//user Auth Reduser
export const userAuthReducers = (state = { userInfo: []}, action) => {
  switch (action.type) {
    case auth.USER_AUTH_REQUEST:
      return { loading: true };
    case auth.USER_AUTH_SUCCESS_ONE:
      return { loading: false, successOneEmail: true,message:action.payload };
    case auth.USER_AUTH_SUCCESS_TWO:
      return { loading: false, userInfo: action.payload };

    case auth.USER_AUTH_FAIL:
      return { loading: false, error: action.payload };
    // case auth.USER_AUTH_FAIL:
    //   return { loading: false, error: action.payload };
    case auth_otp.USER_AUTH_OTP_REQUEST:
      return { loading: true };
    case auth_otp.USER_AUTH_OTP_SUCCESS_ONE:
      return { loading: false, successOne: true ,message:action.payload };

    case auth_otp.USER_AUTH_OTP_SUCCESS_TWO:
      return { loading: false, userInfo: action.payload  };

    case auth_otp.USER_AUTH_OTP_FAIL:
      return { loading: false, error: action.payload };

    case auth.USER_SIGN_OUT:
      return {};
    default:
      return state;
  }
};

///user Register Reducer

export const userRegisterReducer = (state = { registerData: [] }, action) => {
  switch (action.type) {
    case register.USER_REGISTER_REQUEST:
      return { loading: true };
    case register.USER_REGISTER_SUCCESS:
      return { loading: false, success: true, registerData: action.payload };
    case verifyConst.GET_USER_VERIFY_DATA:
      return {
        loading: false,
        verifyData: action.payload,
        verifySuccess: true,
      };
    case register.USER_REGISTER_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
//verify user registration
export const userVerifyRegisterReducer = (state = {}, action) => {
  switch (action.type) {
    case verifyConst.USER_VERIFY_REGISTER_REQUEST:
      return { loading: true };
    case verifyConst.USER_VERIFY_REGISTER_SUCCESS:
      return { loading: false, verifySuccess: true };
    case verifyConst.USER_VERIFY_REGISTER_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const verfiyCodeReducer = (state = { pass: [] }, action) => {
  switch (action.type) {
    case verifyConst.USER_verifyConst_REQUSET:
      return { loading: true };

    case verifyConst.USER_verifyConst_SUCCESS:
      return { loading: false, pass: action.payload };

    case verifyConst.USER_verifyConst_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const userAuthOTPReducers = (state = { userInfo: [] }, action) => {
  switch (action.type) {
    case auth_otp.USER_AUTH_OTP_REQUEST:
      return { loading: true };
    case auth_otp.USER_AUTH_OTP_SUCCESS:
      return { loading: false, userInfo: action.payload };

    case auth_otp.USER_AUTH_OTP_FAIL:
      return { loading: false, error: action.payload };
    // case auth.USER_AUTH_FAIL:
    //   return { loading: false, error: action.payload };

    case auth.USER_SIGN_OUT:
      return {};
    default:
      return state;
  }
};
