import { Container } from "@material-ui/core";
import MobileHeader from "./header/MobileHeader";

const DefaultLayout = ({ children }) => {
    return (
        <div className="Gird_Container">
            <header className='w-100 ' style={{ position: "absolute", zIndex: 100 }}>

                <MobileHeader />

            </header>
            <main>
                <Container maxWidth="lg" className='p-0'>{children}</Container>
            </main>
            {/* <footer>
                <Footer/>
            </footer> */}
        </div>
    );
};

export default DefaultLayout;
