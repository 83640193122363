import { cartConst } from "../constant/cart-constant";

export function cartReducer(state = { cardList: [] }, action) {
  switch (action.type) {
    case cartConst.ADD_TO_CART_SUCCESS:
      return {
        ...state,
        cardList: [...action.payload],
      };
    case cartConst.REMOVE_FROM_CART_SUCCESS:
      return {
        ...state,
        cardList: [...action.payload],
      };
    case cartConst.UPDATE_CART:
      return {
        ...state,
        cardList: [...action.payload],
      };
    default:
      return state;
  }
}
