import { cartConst } from "../constant/cart-constant";

export const addToCart = (cardData) => {
    let cardList = JSON.parse(localStorage.getItem("cardList") || "[]");
    if (cardList.every((c) => c.id !== cardData.id)) {
        cardList.push(cardData);
        localStorage.setItem("cardList", JSON.stringify(cardList));
    } else {
        //if card already existed
    }

    return {
        type: cartConst.ADD_TO_CART_SUCCESS,
        payload: cardList,
    };
};

export const removeFromCart = (cardId) => {
    let cardList = JSON.parse(localStorage.getItem("cardList"));
    const newList = cardList.filter((c) => c.id !== cardId);
    localStorage.setItem("cardList", JSON.stringify(newList));

    return {
        type: cartConst.REMOVE_FROM_CART_SUCCESS,
        payload: newList,
    };
};

export const updateCart = (cardId, updateVal) => {
    let cardList = JSON.parse(localStorage.getItem("cardList")) || [];
    const index = cardList && cardList.findIndex((c) => c.id === cardId);

    let updateCard = {
        ...cardList.filter((c) => c.id === cardId)[0],
        ...updateVal,
    };
    let newList = [...cardList];
    newList[index] = updateCard;
    localStorage.setItem("cardList", JSON.stringify(newList));

    return {
        type: cartConst.UPDATE_CART,
        payload: newList,
    };
};
export const clearCart = () => {

    localStorage.setItem("cardList", JSON.stringify([]));

    return {
        type: cartConst.UPDATE_CART,
        payload: [],
    };
};