import {createStore, compose, applyMiddleware} from "redux";
import thunk from "redux-thunk";
import reducers from "./reducers";

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const initialState = {
    auth: {
        userInfo: localStorage.getItem("userInfo")
            ? JSON.parse(localStorage.getItem("userInfo"))
            : null,
    },
    cartData: {
        cardList: localStorage.getItem("cardList")
            ? JSON.parse(localStorage.getItem("cardList"))
            : null,
    },

};

const store = createStore(
    reducers,
    initialState,
    composeEnhancer(applyMiddleware(thunk))
);

export default store;
