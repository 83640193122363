import React, {useEffect} from "react";
import {Link, useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Button, Paper} from "@material-ui/core";

const Error404 = () => {
    const history = useHistory();
    useEffect(() => {
        setTimeout(() => {
            history.push("/")
        }, 4000)
    }, []);
    const {t} = useTranslation()
    return (
        <div
            className="authincation d-flex justify-content-center align-items-center  p-meddle mb-5"
            style={{height: "100vh"}}
        >
            <div className="container h-100">
                <div className="row justify-content-center h-100 align-items-center ">
                    <Paper className={"bg-dark bg-404"}>
                    <div className="col-md-8">
                        <div className="row">
                            <div className="col-md-4">
                                <span className="error-text font-weight-bold d-flex align-items-center justify-content-center h404">404</span>
                            </div>
                            <div className="col-md-8">
                                <div className=" error_page">
                                    <h4 className={""}>
                                        {t('Not_Found')}
                                    </h4>
                                    <p>
                                        {t('YOU_MAY_HAVE')}
                                    </p>
                                    <Button variant="contained" color="primary" size={"large"} className={"fs-15"}>
                                        <Link className="text-white fs-15" to="/">
                                            {t('BACK_TO_HOME')}
                                        </Link>
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    </Paper>
                </div>
            </div>
        </div>
    );
};

export default Error404;
