import React, { useEffect } from "react";
import SingleGridCard from "../SingleGridCard/SingleGridCard";

import { Grid } from "@material-ui/core";
import PageTitle from "../PageTitle/PageTitle";
import { useDispatch } from "react-redux";
import { getHomeData } from "../../redux/actions/home-actions";

import Skeleton from "@material-ui/lab/Skeleton";

import { useTranslation } from "react-i18next";

const GiftCart = ({ title, homeData, refScroll }) => {
  const { home, loading } = homeData;

  const { t } = useTranslation();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getHomeData());
  }, [dispatch]);

  return (
    <div ref={refScroll}>
      {title && <PageTitle title={t("most_viewed")} variant="h2" />}
      <Grid className="Cart_Game_head" container spacing={2}>
        {loading ? (
          <Grid className={"mt-5 mb-5 p-2"} container spacing={4}>
            {[1, 2, 3, 4, 5, 6].map((item, i) => (
              <Grid item lg={2} md={3} sm={4} xs={6} key={i}>
                <Skeleton variant="rect"   height={347} />
              </Grid>
            ))}
          </Grid>
        ) : (
          home?.data?.most_visitors_products.map((item, i) => (
            <SingleGridCard key={i} item={item} />
          ))
        )}
      </Grid>
    </div>
  );
};

export default GiftCart;
