import { combineReducers } from "redux";
import {
  userAuthReducers,
  userRegisterReducer,
  userVerifyRegisterReducer,
  verfiyCodeReducer,
} from "./auth-reducers";
import {
  categoriesReducer,
  getCategoriesProductReducer,
} from "./categori-reducer";
import {
  userProfileReducer,
  userEditProfileReducer,
} from "../reducers/userRreducer";
import { homeDataReducer,homeSearchReducer } from "../reducers/home-reducer";
import { orderPostReducer, ordersListGetReducer } from "./order-reducers";
import { cartReducer } from "./cart-reducer";

const reducers = combineReducers({
  auth: userAuthReducers,
  register: userRegisterReducer,
  getCategorie: categoriesReducer,
  categoryProduct: getCategoriesProductReducer,
  verifyPass: verfiyCodeReducer,
  profile: userProfileReducer,
  editProfile: userEditProfileReducer,
  homeData: homeDataReducer,
  orderData: orderPostReducer,
  cartData: cartReducer,
  verifyRegister: userVerifyRegisterReducer,
  ordersList: ordersListGetReducer,
  homeSearch:homeSearchReducer
});

export default reducers;
