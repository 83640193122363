import Swal from "sweetalert2";

export const STATUS_OK = 200;
export const STATUS_BAD_REQUEST = 400;

export function SUCCESS(message, { description } = {}) {
  return Swal.fire({
    title: message,
    html: `<!--<h5 class="text-dark">${message}</h5> ${description || ""}-->`,
    icon: "success",
    showConfirmButton: false,
    allowOutsideClick: true,
    showCloseButton: true,
  });
}

export function ERROR(message, { description, error } = {}) {
  return Swal.fire({
    html: `<h5 class="text-dark">${message}</h5> ${description || ""} `,
    // title: message,
    //     html: `<h5 class="text-dark">${message}</h5> ${description || ""}
    // ${
    //   !!error
    //     ? `<p style="white-space: pre-wrap" class="dir-left bg-soft-dark rounded p-3 text-right text-dark font-size-12">${JSON.stringify(
    //         error.response?.data || error.response || error.data || error,
    //         false,
    //         "\t"
    //       )}</p>`
    //     : ""
    // }
    // `,
    icon: "error",
    showConfirmButton: false,
    allowOutsideClick: true,
    showCloseButton: true,
  });
}

export function LOADING(show) {
  if (show) {
    Swal.fire({
      showConfirmButton: false,
      allowOutsideClick: false,
      showCloseButton: false,
    });
    return Swal.showLoading();
  } else {
    Swal.hideLoading();
    return Swal.close();
  }
}

export function CONFIRM(
  message,
  callback,
  { description, confirmText = "بله", cancelText = "انصراف" } = {}
) {
  return Swal.fire({
    icon: "warning",
    html: `<h5 class="text-dark">${message}</h5> ${description || ""}`,
    showConfirmButton: true,
    confirmButtonText: confirmText,
    showCancelButton: true,
    cancelButtonText: cancelText,
  }).then((result) => {
    if (result.value) {
      callback(result);
    }
  });
}

export function WARNING(message, { description, error } = {}) {
  return Swal.fire({
    title: message,
    html: `<span>${!!description ? description : ""}</span>`,
    icon: "info",
    showConfirmButton: true,
    allowOutsideClick: false,
    showCloseButton: false,
  });
}

export function WAllET_INFO(message, { description } = {}) {
  return Swal.fire({
    title: message,
    text: description,
    icon: "info",
    showConfirmButton: false,
    allowOutsideClick: true,
    showCloseButton: true,
  });
}
