import { userConst } from "../constant/user-constant";

export const userProfileReducer = (state = { userDetial: [] }, action) => {
  switch (action.type) {
    case userConst.USER_PROFILE_REQUSET:
      return { loading: true };
    case userConst.USER_PROFILE_SUCCESS:
      return { loading: false, userDetial: action.payload };
    case userConst.USER_PROFILE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const userEditProfileReducer = (state = {}, action) => {
  switch (action.type) {
    case userConst.USER_EDIT_PROFILE_REQUSET:
      return { loading: true };
    case userConst.USER_EDIT_PROFILE_SUCCESS:
      return { loading: false, success: true };
    case userConst.USER_EDIT_PROFILE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
