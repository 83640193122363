import { HomeConst } from "../constant/home-constant";

export const homeDataReducer = (state = { home: []}, action) => {
  switch (action.type) {
    case HomeConst.GET_HOME_REQUSET:
      return { ...state,loading: true };
    case HomeConst.GET_HOME_SUCCESS:
      return { ...state,loading: false, home: action.payload };
    case HomeConst.GET_HOME_FAIL:
      return { ...state,loading: false, error: action.payload };
    // case HomeConst.GET_SEARCHPRODUCT_REQUEST:
    //   return {...state,loading: true}
    // case HomeConst.GET_SEARCHPRODUCT_SUCCESS:
    //   return {...state,loading: false,searchProduct: action.payload}
    // case HomeConst.GET_SEARCHPRODUCT_FAIL:
    //   return {...state,loading: false,error: action.payload}
    default:
      return state;
  }
};

export const homeSearchReducer = (state={searchProduct:[]},action)=>{
  switch (action.type){

    case HomeConst.GET_SEARCHPRODUCT_REQUEST:
      return {...state,loading: true}
    case HomeConst.GET_SEARCHPRODUCT_SUCCESS:
      return {...state,loading: false,searchProduct: action.payload}
    case HomeConst.GET_SEARCHPRODUCT_FAIL:
      return {...state,loading: false,error: action.payload}
    default:
      return state;
  }

}