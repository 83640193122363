import React, { useEffect } from "react";
import MainRoute from "./App/Router/MainRoute";
import ThemeContext from "./App/context/ThemeContext";
import axios from "axios";
import { ERROR } from "./App/feature/alerts";
import { SnackbarProvider, useSnackbar } from 'notistack';
import Slide from '@material-ui/core/Slide';
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
function SnackbarCloseButton({ snackbarKey }) {
    const { closeSnackbar } = useSnackbar();

    return (
        <div style={{ position: "absolute", left: 0, top: 0, bottom: 0, display: 'flex', justifyContent: "center", alignItems: "center" }}>
            <IconButton style={{ color: "#fff" }} onClick={() => closeSnackbar(snackbarKey)}>
                <Close fontSize="large" />
            </IconButton>
        </div>
    );
}

const App = (props) => {

    return (
        <ThemeContext>
            <SnackbarProvider
                style={{ paddingLeft: 33, textAlign: "right" }}
                action={snackbarKey => <SnackbarCloseButton snackbarKey={snackbarKey} />}
                onDurationChangeCapture={"300"}
                autoHideDuration={1500}
                TransitionComponent={Slide}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                maxSnack={1}>
                <MainRoute />
            </SnackbarProvider>
        </ThemeContext>
    );
};

export default App;


axios.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        if (error === "Cannot read property 'status' of undefined") {
            ERROR("سایت در حال حاضر در دسترس نیست");
        } else if (401 === error.response.status) {
            localStorage.removeItem("userInfo");
            localStorage.removeItem("cardList");
            if (process.browser) {
                window.location = "/";
            }
        } else if (403 === error.response.status) {
            ERROR(error?.response?.data?.message)
            if (process.browser) {
                window.location = "/";
            }
        } else {
            return Promise.reject(error);
        }
    }
);

