import axios from "axios";
import i18n from "i18next";

const baseURL = process.env.REACT_APP_BASE_URL;

export const _http = (
  url,
  { params, data, method, headers = {}, token, body, ...res }
) => {
  return axios({
    baseURL,
    url,
    headers: {
      Authorization: `Bearer ${JSON.parse(localStorage.getItem("userInfo"))}`,
      'Accept-Language': i18n.language,
      ...headers,
    },
    params,
    data,
    body,
    method,
    ...res,
  })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      if (e?.response?.status === 401 && typeof window !== "undefined") {
        localStorage.removeItem("B_R_token");
        return (window.location.href = "/login");
      }
      if (e?.response?.status === 403 && typeof window !== "undefined") {
        localStorage.removeItem("B_R_token");
        return (window.location.href = "/login");
      }
      return e;
    });
};
