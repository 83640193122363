import React from "react";
import { Typography, Button, Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const Slider = ({ executeScroll }) => {
  const { t } = useTranslation()
  return (
    <div className='slider_top ' style={{ marginTop: '-10px' }}>
      <Box component='div' className="mr-auto">
        {/* <Typography  className='Slider_Title ' variant='h2' component='h2'>
            {t("main_title_big")}
        </Typography>
        <Typography className='Slider_Title' variant='h3' component='h1'>
            {t("main_title_small")}

        </Typography>
          <Button className='btn_Buy text-uppercase' variant='contained' color='primary' onClick={executeScroll}>
              {t("buy_now")} !
        </Button> */}

      </Box>
    </div>
  );
};

export default Slider;
