import React, { useEffect, useRef, useState } from "react";
import { Home, Receipt } from "@material-ui/icons";
import { Badge, IconButton } from "@material-ui/core";
import { ShoppingCart } from "@material-ui/icons";
import { withStyles } from "@material-ui/core/styles";
import SearchIcon from '@material-ui/icons/Search';
import Search from "../../components/Search/Search";
import { Link } from "react-router-dom";
import { CEHCkOUT, PROFILE } from "../../constant/Router.js";
import { useSelector } from "react-redux";
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
import { useTranslation } from "react-i18next";

const StyledBadge = withStyles((theme) => ({
    badge: {
        backgroundColor: "#FF8A34",
        right: 20,
        color: "#fff",
        padding: 0
    },
}))(Badge);

const MobileHeader = () => {
    const cardList = useSelector((state) => state.cartData.cardList);

    const [showSearch, setShowSearch] = useState(false)


    const searchContainerRef = useRef(null)

    const { t } = useTranslation()
    useEffect(() => {
        const onGlobalClick = (event) => {

            if (searchContainerRef.current && !searchContainerRef.current.contains(event.target)) {
                setShowSearch(false)
            }
        };

        document.addEventListener("mousedown", onGlobalClick);

        return () => document.removeEventListener("mousedown", onGlobalClick);
    }, []);

    const handleSearchClick = () => {
        setShowSearch(true)
    }

    return (
        <div className="mobile_nav_header">
            {/*<Container maxWidth="lg">*/}
            <div className="nav_align_item  ">
                <IconButton className='nav-icon' onClick={handleSearchClick}>
                    <SearchIcon style={{ color: "#aaa", fontSize: '20px' }} />
                </IconButton>
                <div className="d-flex align-items-center ">

                    <IconButton
                        component={Link}
                        to={CEHCkOUT}
                        aria-label="cart"
                        disabled={cardList?.length === 0}
                        className="Shop_Cart nav-icon"
                    >
                        <StyledBadge
                            badgeContent={cardList ? cardList.length : 0}
                        >
                            <ShoppingCart style={{ color: "#aaa", fontSize: '20px' }} />
                        </StyledBadge>
                    </IconButton>
                    <IconButton className='nav-icon' component={Link}
                        to={PROFILE}>
                        <Receipt style={{ color: "#aaa", fontSize: '20px' }} />
                    </IconButton>
                    <IconButton className='nav-icon' component={Link}
                        to={"/"}>
                        <Home style={{ color: "#aaa", fontSize: '20px' }} />
                    </IconButton>
                </div>
            </div>
            <span ref={searchContainerRef}
                className={`mobileSearch_container mobileSearch_container_${showSearch ? `show` : ''} d-flex justify-content-between align-items-center`}>
                <Search setShowSearch={setShowSearch} />
                <CancelPresentationIcon fontSize="large" color='primary' className='search_closeBtn'
                    onClick={(e) => setShowSearch(false)} />
            </span>
        </div>
    );
};

export default MobileHeader;
