import React from "react";

import {
  Box,
  Button,
  Grid,
  Typography,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { AddShoppingCart } from "@material-ui/icons";
import { history } from "../../Router/history";
import { useSelector, useDispatch } from "react-redux";
import { addToCart, updateCart } from "../../redux/actions/cart-action";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import Flag from "react-world-flags";
import { getCode } from "country-list";
var countries = require("i18n-iso-countries");
countries.registerLocale(require("i18n-iso-countries/langs/fa.json"));
function SingleGridCard(props) {
  const { enqueueSnackbar } = useSnackbar();

  const { item } = props;

  const dispatch = useDispatch();

  const cardList = useSelector((state) => state.cartData.cardList);

  const { t } = useTranslation();

  const addCart = (item) => {
    let fa =
      t("direction") === "rtl"
        ? `${item?.name} ${t("Cart_SUCCESS_ADD")}`
        : `${item?.name} ${t("Cart_SUCCESS_ADD")}`;
    enqueueSnackbar(fa, {
      variant: "default",
    });
    if (cardList) {
      const index = cardList.findIndex((i) => i.id === item.id);
      if (index < 0) {
        dispatch(addToCart({ ...item, card_num: 1, amount: item.min_price }));
      } else {
        const cardNum = cardList[index].card_num;

        dispatch(updateCart(item.id, { card_num: cardNum + 1 }));
      }
    } else {
      dispatch(addToCart({ ...item, card_num: 1 }));
    }
  };

  const count = () => {
    const index = cardList?.findIndex((i) => i.id === item.id);
    const cardNum = index > -1 ? cardList[index].card_num : 0;
    return cardNum;
  };
  const handleBuyClick = () => {
    let addItem =
      t("direction") === "rtl"
        ? `${item?.name} ${t("Cart_SUCCESS_ADD")}`
        : `${item?.name} ${t("Cart_SUCCESS_ADD")}`;
    enqueueSnackbar(addItem, {
      variant: "default",
    });
    if (cardList) {
      const index = cardList.findIndex((i) => i.id === item.id);

      if (index < 0) {
        dispatch(addToCart({ ...item, card_num: 1, amount: item.min_price }));
      } else {
        const cardNum = cardList[index].card_num;
        dispatch(updateCart(item.id, { card_num: cardNum + 1 }));
      }
    } else {
      dispatch(addToCart({ ...item, card_num: 1, amount: item.min_price }));
    }
    history.push(`/checkout`);
  };
  return (
    <Grid
      style={{ marginTop: 10 }}
      item
      lg={2}
      md={3}
      sm={4}
      xs={6}
      key={`
        giftCart_$
        {
            item.id
        }
        `}
    >

      <Box className="Card_media_top_container">
        <Box className="Card_media Cart_Game_head">
          <Link
            to={{
              pathname: `/products/${item.slug}`,
              state: { item },
            }}
          ><img className="Card_img" src={item.photo} alt="" />
          </Link>
          <Flag
            className="country_flag"
            code={
              item?.country?.length > 3 ? getCode(item?.country) : item?.country
            }
            title={item.country}
            height="16"
            width={27}
            fallback={<span>{item?.country}</span>}
          />
          {item?.country && <span style={{ position: "absolute", bottom: 8, right: 5, color: "#fff", padding: 3, paddingRight: 10, paddingLeft: 10, backgroundColor: "#00A693", borderRadius: 15 }}>{countries?.getName(item?.country?.length > 3 ? getCode(item?.country) : item?.country, "fa")}</span>}
        </Box>
        <Box className="Cart_list_items productName">
          <Typography variant="h5" component="h3">
            <Link
              className="Cart_link"
              to={{
                pathname: `/products/${item.slug}`,
                state: { item },
              }}
            >
              {item.name}
            </Link>
          </Typography>
        </Box>
        <Box className="Cart_list_items">
          <Typography variant="h5" className="price" component="h3">
            {item.selling_price.price.toLocaleString("fa")} تومان
          </Typography>
        </Box>
      </Box>

      <Box className="Cart_list_items btn">
        {item.qty !== 0 ? (
          <div className="cartBtn ">
            <div className="cartBtn_cartIconNum">
              <Button
                disabled={item.qty === 0}
                onClick={() => addCart(item)}
                className={"btn_add_Cart"}
              >
                <AddShoppingCart style={{ color: "#00C9AC" }} />
                {count() !== 0 && (
                  <span
                    className="cardNum"
                    style={t("direction") !== "rtl" ? { right: "-10px" } : {}}
                  >
                    {count().toLocaleString("fa")}
                  </span>
                )}
              </Button>
            </div>
            <div className="btn_buy_c">
              <Button
                disabled={item.qty === 0}
                className="btn_buy"
                variant="contained"
                color="primary"
                onClick={() => handleBuyClick()}
              >
                {item.qty === 0 ? t("OUT_OF_STOCK") : t("buy")}
              </Button>
            </div>
          </div>
        ) : (
          t("OUT_OF_STOCK")
        )}
      </Box>
    </Grid >
  );
}

export default SingleGridCard;
