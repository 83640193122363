export const auth = {
  USER_AUTH_REQUEST: "USER_AUTH_REQUEST",
  USER_AUTH_SUCCESS_ONE: "USER_AUTH_SUCCESS_ONE",
  USER_AUTH_SUCCESS_TWO: "USER_AUTH_SUCCESS_TWO",
  USER_AUTH_FAIL: "USER_AUTH_FAIL",
  USER_SIGN_OUT: "USER_SIGN_OUT",
};

export const auth_otp = {
  USER_AUTH_OTP_REQUEST: "USER_AUTH_OTP_REQUEST",
  USER_AUTH_OTP_SUCCESS_ONE: "USER_AUTH_OTP_SUCCESS_ONE",
  USER_AUTH_OTP_SUCCESS_TWO: "USER_AUTH_OTP_SUCCESS_TWO",
  USER_AUTH_OTP_FAIL: "USER_AUTH_OTP_FAIL",
  USER_SIGN_OUT: "USER_SIGN_OUT",
};
export const register = {
  USER_REGISTER_REQUEST: "USER_REGISTER_REQUEST",
  USER_REGISTER_SUCCESS: "USER_REGISTER_SUCCESS",
  USER_REGISTER_FAIL: "USER_REGISTER_FAIL",
};

export const forgotPassword = {
  USER_FORGOTPASS_REQUSET: "USER_FORGOTPASS_REQUSET",
  USER_FORGOTPASS_SUCCESS: "USER_FORGOTPASS_SUCCESS",
  USER_FORGOTPASS_FAIL: "USER_FORGOTPASS_FAIL",
};

export const verifyConst = {
  USER_verifyConst_REQUSET: "USER_verifyConst_REQUSET",
  USER_verifyConst_SUCCESS: "USER_verifyConst_SUCCESS",
  USER_verifyConst_FAIL: "USER_verifyConst_FAIL",
  GET_USER_VERIFY_DATA: "GET_USER_VERIFY_DATA",

  USER_VERIFY_REGISTER_REQUEST: "USER_VERIFY_REGISTER_REQUEST",
  USER_VERIFY_REGISTER_SUCCESS: "USER_VERIFY_REGISTER_SUCCESS",
  USER_VERIFY_REGISTER_FAIL: "USER_VERIFY_REGISTER_FAIL",
};
